import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import lookingIllustration from '../images/looking.svg';
import { Link } from 'gatsby';

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="flex flex-col mt-8 xxl:mt-16">
        <img
          alt="Backpacker seeking destination"
          className="block mx-auto w-3/4 lg:w-1/2"
          src={lookingIllustration}
        />
        <div className="flex flex-row justify-center mt-8 xxl:mt-16">
          <h2 className="flex-initial w-auto text-2xl font-bold my-2 p-3">
            Looks like you are far from what you are seeking ...{` `}
            <Link to="/">
              <a
                className="font-bold text-funky-blue"
                href="mailto:hi@bytewrap.com"
              >
                let&apos;s go back
              </a>
            </Link>
          </h2>
        </div>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
